
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { ApiBase } from "@/core/api";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { getSalesOrderFulfillmentStatusOptions } from "@/core/directive/function/salesOrder";
import { TaggingItem } from "@/core/directive/interface/common";
import { SalesOrderNewStatus } from "@/core/directive/type/salesOrder";
import WindowResize from "@/components/layout/WindowResize.vue";
import { removeReadonly } from "@/core/directive/function/common";

interface Filter {
  order_number: string;
  fulfillment_status: string;
  priority_level: string;
  status: string[];
  settlement_status: string[];
  external_order_number: string;
  customer_id: string;
  purchase_information: string;
  brand_id: string;
  article_no: string;
  order_date_start: string;
  order_date_end: string;
}

export default defineComponent({
  name: "filter-sales-orders-dropdown",
  props: {
    showType: { type: Number, default: 1 }, // 1正常显示状态过滤
    statusValue: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: { WindowResize },
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      order_number: "",
      status: [],
      settlement_status: [],
      fulfillment_status: "",
      external_order_number: "",
      customer_id: "",
      priority_level: "",
      purchase_information: "",
      brand_id: "",
      article_no: "",
      order_date_start: "",
      order_date_end: "",
    });

    const options = ref({
      status: [] as TaggingItem[],
      defaultSelectStatus: [
        {
          label: t("salesOrderOverview.pendingApproval"),
          value: SalesOrderNewStatus.New + "," + SalesOrderNewStatus.Submit,
        },
      ] as TaggingItem[],
      selectStatus: [] as TaggingItem[],
      statusAll: [],
      mb_order_settlement_status: [] as TaggingItem[],
      customer: [] as TaggingItem[],
      customerLoading: false,
      fulfillment_status: getSalesOrderFulfillmentStatusOptions(t),
      high_priority_order: [
        {
          label: t("salesOrder.priorityNormal"),
          value: "0",
        },
        {
          label: t("salesOrder.priorityVip"),
          value: "1",
        },
      ],
      priority_level: [] as TaggingItem[],
      purchase_information: [
        // 跟履约来源状态相反，订单这里是预计来源
        {
          label: t("fulfillmentOrder.fulfillmentSourcePreStock"),
          value: "1",
        },
        {
          label: t("fulfillmentOrder.fulfillmentSourceMerchant"),
          value: "2",
        },
      ],
      brandLoading: false,
      brand: [] as TaggingItem[],
    });

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const submit = () => {
      if (formData.value.status.length === 0) {
        if (props.showType === 1) {
          formData.value.status = options.value.statusAll as string[];
        } else {
          formData.value.status = props.statusValue as string[];
        }
      }
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      formData.value.status = props.statusValue as string[];
      emit("reset-filter");
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "mb_order_status",
          "mb_order_settlement_status",
          "sales_order_priority_level",
        ],
      });
      if (data.code == 0) {
        options.value.status = data.data.mb_order_status.items;
        options.value.priority_level =
          data.data.sales_order_priority_level.items;
        setSelectStatus();
        options.value.statusAll = data.data.mb_order_status.items.map(
          (item) => {
            return item.value;
          }
        );
        options.value.mb_order_settlement_status =
          data.data.mb_order_settlement_status.items;
      }
    };

    const getBrandSourceData = async (value, id?) => {
      options.value.brandLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getBrandSourceData(params);
      options.value.brandLoading = false;
      if (data.code == 0) {
        options.value.brand = data.data;
      }
    };

    const debounceBrandSourceSearch = _.debounce(getBrandSourceData, 1000);

    const searchBrandSourceItems = (query: string) => {
      debounceBrandSourceSearch(query);
    };

    const setSelectStatus = () => {
      let arr: TaggingItem[] = [];
      arr.push(...options.value.defaultSelectStatus);
      arr.push(
        ...options.value.status.filter(
          (item) => Number(item.value) === SalesOrderNewStatus.Verified
        )
      );
      options.value.selectStatus = arr;
    };

    const changeAdditionalService = () => {
      formData.value.status = [
        options.value.defaultSelectStatus[0].value as string,
      ];
      submit();
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer = data.data;
      }
    };

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    onMounted(() => {
      getDropdownOptions();
      getBrandSourceData("");
      formData.value.status = props.statusValue as string[];
      cancelReadonly(false);
    });

    return {
      t,
      props,
      formData,
      formRef,
      submit,
      handleReset,
      options,
      cancelReadonly,
      changeAdditionalService,
      searchCustomerItems,
      searchBrandSourceItems,
    };
  },
});
