
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import { ApiSalesOrders } from "@/core/api";
import CommonProduct from "./CommonProduct.vue";

export default defineComponent({
  components: { CommonProduct },
  name: "kt-product-default-table",
  props: {
    order_id: {
      type: Number,
      required: true,
      default: () => {
        return 0;
      },
    },
    isExpand: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      },
    },
  },
  setup(props) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const base = ref("");
    const order = ref("");
    const isRequest = ref<boolean>(false);

    const formData = ref({
      ordered_items: [] as any[],
    });

    const options = ref({
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    const getCompanyData = async () => {
      loading.value = true;
      const { data } = await ApiSalesOrders.getSalesOrderListItemInfo({
        order_id: props.order_id,
      });
      loading.value = false;
      isRequest.value = true;
      if (data.code == 0) {
        formData.value.ordered_items = data.data.items;
        base.value = data.data.base_currency_symbol + " ";
      }
    };

    const previewImages = (
      previewImages: Array<string>,
      imageIndex: number
    ) => {
      options.value.sources = previewImages;
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const getName = computed(() => {
      return (row) => {
        return JwtService.getLang() === "zh_CN" ? row.name : row.name_en;
      };
    });

    const getTotal = computed(() => {
      return (price, qty) => {
        return Number(price) * Number(qty);
      };
    });

    onMounted(() => {
      getCompanyData();
    });

    return {
      t,
      props,
      loading,
      base,
      order,
      options,
      isRequest,
      previewImages,
      getName,
      getTotal,
      formData,
    };
  },
});
